<template>
	<div class="about-total">
		<div v-if="img_array[img_index].split(',').length == 1" class="content">
			<img :src="img_array[img_index]" class="img" />
		</div>
		<div v-else class="content">
			<img :src="img_array[img_index].split(',')[0]" class="img1" />
			<img :src="img_array[img_index].split(',')[1]" class="img2" />
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				img_index: 0,
				img_array: [],
			};
		},
		created() {
			this.img_index = this.$route.query.index;
			if (this.$app == "kh") {
				this.img_array = [
					"https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc/about_swhj.png", //商务会奖
					"", //业务范围
					"", //产品介绍
					"https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc/about_gsjj.png", //公司简介 关于我们
					"https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc/about_qywh.png", //企业文化
					"https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc/about_gsry.png", //公司荣耀
					"", //标准化建设
					"https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc/about_lxwm.png", //联系我们 咨询客服
					"", //8会议会奖
					"https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc/zyx_jp_tt.png,https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc/about_jpyw.png", //9自由行-机票
					"https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc/zyx_zc_tt.png,https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc/about_zc.png", //10自由行-租车
					"https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc/khdz_tt.png,https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc/about_khdznew.png", //11自由行-定制
					"https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc/about_qyzz.png", //12企业资质
					"https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc/about_yywd.png", //13营业网点
					"https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc/kanghuizhaoshang.png" //14招商
				];
			} else if (this.$app == "dy") {
				if (this.$route.query.org == 1) {
					var img = "https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc_dy/about_qyzz1.png"
				} else if (this.$route.query.org == 2) {
					var img = "https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc_dy/about_qyzz2.png"
				} else if (this.$route.query.org == 3) {
					var img = "https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc_dy/about_qyzz3.png"
				} else {
					var img = "https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc_dy/about_qyzz.png"
				}
				this.img_array = [
					"https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc_dy/about_swhj.png", //商务会奖
					"", //业务范围
					"", //产品介绍
					"https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc_dy/about_gsjj.png", //公司简介 关于我们
					"https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc_dy/about_qywh.png", //企业文化
					"https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc_dy/about_gsry.png", //公司荣耀
					"", //标准化建设
					"https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc_dy/about_lxwm.png", //联系我们 咨询客服
					"", //8会议会奖
					"https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc_dy/zyx_jp_tt.png,https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc_dy/dyt_jp.png", //9自由行-机票
					"https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc_dy/dyt_zcbanner.png,https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc_dy/dyt_zc.png", //10自由行-租车
					"https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc_dy/dyt_dzbanner.png,https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc_dy/dyt_dz.png", //11自由行-定制
					img, //12企业资质
					"https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc_dy/about_yywd.png", //13营业网点
					"https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc/dayunzhaoshang.png" //14招商
				];
			}

		},
	};
</script>

<style lang='less' scoped>
	.about-total {
		display: flex;
		width: 100vw;
		flex-direction: column;
		align-items: center;
		background-color: #fff;

		.content {
			display: flex;
			width: 100%;
			flex-direction: column;
			align-items: center;
			background-color: #fff;
		}

		.img {
			width: 1200px;
			height: auto;
		}

		.img1 {
			width: 100%;
			height: auto;
		}

		.img2 {
			margin-top: 50px;
			width: 1200px;
			height: auto;
		}
	}
</style>
